import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Rating,
} from "@material-tailwind/react";
import DefaultButton from "../common/DefaultButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../slices/cartSlice";
import { FaCartShopping } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { removeFromCart } from "../../slices/cartSlice";

export function ProductCard({ productData }) {
  const [rated, setRated] = useState(0);
  const [reviewStars, setReviewStars] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);
  // const [allProductImages, setAllProductImages] = useState([]);

  // useEffect(() => {
  //   for (const image of productData?.images) {
  //     setAllProductImages(...allProductImages, image.images_url);
  //   }
  //   console.log(allProductImages);
  // }, [productData]);

  const checkCart = () => {
    let isPresent = false;
    if (!cart) {
      return isPresent;
    }

    cart.forEach((item) => {
      if (item.productId === productData?._id) {
        isPresent = true;
      }
    });
    return isPresent;
  };

  useEffect(() => {
    let totalRating = 0;
    if (productData?.ratingAndReviews.length > 0) {
      productData?.ratingAndReviews?.forEach((item) => {
        totalRating += item?.rating;
      });
      setRated(
        (totalRating / productData?.ratingAndReviews?.length).toFixed(1)
      );
      setReviewStars(Math.round(rated));
    }
  }, [productData]);

  return (
    <Card className="w-full max-w-[26rem] h-[31rem] shadow-lg">
      <CardHeader
        onClick={() => {
          navigate(`/product/${productData._id}`);
        }}
        className="cursor-pointer"
        floated={false}
        color="blue-gray"
      >
        <img src={productData.images[0].images_url[0]} alt={productData.name} />
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />

        {/* heart icon */}
        {/* <IconButton
          size="sm"
          color="red"
          variant="text"
          className="!absolute top-4 right-4 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6 w-6"
          >
            <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
          </svg>
        </IconButton> */}
      </CardHeader>
      <CardBody
        className="cursor-pointer"
        onClick={() => {
          navigate(`/product/${productData._id}`);
        }}
      >
        <div className="mb-3 flex items-center justify-between">
          <Typography variant="h5" color="blue-gray" className="font-medium">
            {productData.name}
          </Typography>
          {reviewStars > 0 && (
            <Typography
              color="blue-gray"
              className="flex items-center gap-1.5 font-normal"
            >
              <Rating
                value={reviewStars}
                readonly
                unratedColor="amber"
                ratedColor="amber"
              />
              {rated}
            </Typography>
          )}
        </div>
        <Typography color="gray">
          <p className="line-clamp-2">{productData.description}</p>
        </Typography>
        {/* <div className="group mt-8 inline-flex flex-wrap items-center gap-3">
          <Tooltip content="$129 per night">
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                <path
                  fillRule="evenodd"
                  d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
                  clipRule="evenodd"
                />
                <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
              </svg>
            </span>
          </Tooltip>
          <Tooltip content="Free wifi">
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.062 0 8.25 8.25 0 00-11.667 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.204 3.182a6 6 0 018.486 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0 3.75 3.75 0 00-5.304 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182a1.5 1.5 0 012.122 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0l-.53-.53a.75.75 0 010-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </Tooltip>
        </div> */}

        <div className="mt-5 flex items-end justify-end space-x-3">
          <span className="text-green-600 font-semibold text-xl">
            ₹{productData.discountedPrice}
          </span>
          <span className="text-gray-400 line-through text-lg">
            ₹{productData.originalPrice}
          </span>
        </div>
      </CardBody>
      <CardFooter className="pt-3 flex gap-3">
        {/* <Button
          variant="outlined"
          color="red"
          size="lg"
          fullWidth={true}
        >Buy now</Button> */}
        {/* <Button size="lg" color="red" fullWidth={true}> */}
        <Button
          className={`w-full ${checkCart() ? "" : "bg-gradient"}`}
          onClick={() => {
            if (checkCart()) {
              dispatch(removeFromCart(productData._id));
            } else {
              const data = {
                productId: productData._id,
                color: productData.images[0].color,
              };
              dispatch(addToCart(data));
              navigate("/cart"); // Navigate to the cart page
            }
          }}
          variant={checkCart() ? "outlined" : "filled"}
          color="red"
        >
          <div className="flex items-center gap-3 justify-center">
            <FaCartShopping />
            {checkCart() ? "Remove From Cart" : "Add To Cart"}
          </div>
        </Button>
        {/* </Button> */}
      </CardFooter>
    </Card>
  );
}
