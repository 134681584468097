import React, { useEffect, useState } from "react";
import { CarouselWithContent } from "../components/UI/CarouselWithContent";
import Image2 from "../assets/Home/strap.png";
import Image1 from "../assets/Home/IMG_4876.png";
import { useNavigate } from "react-router-dom";
import ReviewSlider from "../components/Home/ReviewSlider";
import { getAllRatings } from "../services/operations/Product";

const Stats = [
  { count: "500+", label: "Users" },
  { count: "1000+", label: "Orders" },
  { count: "4.8", label: "Reviews" },
];

const Home = () => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);

  // Fetch reviews
  useEffect(() => {
    const fetchAllRatings = async () => {
      const response = await getAllRatings();
      setReviews(response?.data);
    };
    fetchAllRatings();
  }, []);

  return (
    <div className="w-full">
      <div className="relative mx-auto flex w-full flex-col items-center justify-between text-white">
        <CarouselWithContent className="w-full" />

        {/* Wrapper for w-10/12 width */}
        <div className="w-10/12 mx-auto">
          <div className="text-center mb-6 mt-6">
            <h2 className="text-lg sm:text-2xl lg:text-3xl font-bold text-richblack-800 mb-3">
              Unleash Your Inner Demon with
            </h2>
            <h1 className="text-3xl sm:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]">
              DemonGears
            </h1>
          </div>

          <p className="text-center text-base sm:text-xl lg:text-3xl mt-2 mb-10 leading-relaxed font-semibold text-transparent bg-clip-text bg-gradient-to-br from-[#4A4A4A] via-[#6D6D6D] to-[#8C8C8C]">
            Welcome to DemonGears, where passion meets performance in the health
            and wellness industry.
          </p>

          {/* <p className="text-xl sm:text-3xl lg:text-4xl mt-6 mb-6 text-left font-bold text-richblack-800 self-start ml-6 lg:ml-40">
            Explore Our Product
          </p> */}

          <div className="flex flex-col md:flex-row lg:w-11/12 sm:w-full mx-auto">
            <div className="w-full md:w-1/2 bg-red-100 p-5 rounded-xl text-center">
              <h3 className="text-gradient font-bold text-xl lg:text-3xl mb-8 text-left">
                Explore Our Product
              </h3>
              <p className="text-center md:text-left text-sm sm:text-lg lg:text-xl leading-relaxed font-medium text-transparent bg-clip-text bg-gradient-to-br from-[#3A1C1C] via-[#4F2C2C] to-[#6B3E3E]">
                Explore our latest addition to "New Arrivals"—the DemonGears
                premium deadlift straps, engineered to take your lifting
                performance to new heights. Constructed from durable,
                high-density polyester and measuring 21 inches in length by 1.25
                inches in width, these deadlift straps provide ample length to
                wrap securely around any barbell, creating a steadfast grip that
                will withstand even the most intense lifting sessions. The black
                design exudes a professional and bold look, while the
                thoughtfully integrated soft cushioning, measuring 1.5 inches
                wide at the center, offers comfort exactly where it’s needed.
                This cushion sits between your wrists and the bar, minimizing
                discomfort while enhancing your overall grip stability.
              </p>
            </div>
            <div className="w-full md:w-1/2 p-4">
              <img
                src={Image1}
                alt="Upcoming Products"
                className="w-full h-auto lg:w-full lg:h-[500px] shadow-[0_0_20px_0] shadow-[#FC6767]"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-11/12 items-center mx-auto mt-10">
            <div className="w-full md:w-1/2 p-4">
              <img
                src={Image2}
                alt="Upcoming Products"
                className="w-full h-auto lg:w-full lg:h-[500px] shadow-[0_0_20px_0] shadow-[#FC6767]"
              />
            </div>

            <div className="w-full md:w-1/2 bg-red-100 p-5 rounded-xl text-center">
              {/* <h3 className="text-gradient font-bold text-xl lg:text-3xl mb-8 text-left">
                Explore Our Product
              </h3> */}
              <p className="text-center md:text-left text-sm sm:text-lg lg:text-xl leading-relaxed font-medium text-transparent bg-clip-text bg-gradient-to-br from-[#3A1C1C] via-[#4F2C2C] to-[#6B3E3E]">
                Designed for lifters who are serious about their performance,
                these straps are perfect for exercises such as deadlifts,
                shrugs, pull-ups, and rows. The DemonGears deadlift straps
                feature a closed loop on one end and reinforced stitching on the
                other, ensuring durability and resistance to fraying from
                repeated use. These wrist straps don’t just improve grip—they
                transform it. When your grip strength is challenged by
                aggressive knurling or heavy weights, DemonGears straps convert
                a standard grip into a "hyper grip," giving you the leverage to
                lift with confidence and consistency. Whether you're just
                starting out or pushing advanced strength goals, these straps
                are a must-have in your lifting arsenal. Connect with us to stay
                updated on future arrivals tailored to unleash your inner
                strength.
              </p>
              <div className="flex justify-end mt-6 mb-10">
                <button
                  className="relative overflow-hidden rounded-[0.5rem] text-white bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] text-sm sm:text-xl lg:text-2xl font-semibold px-3 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 w-full lg:w-[50%] ml-auto transform transition duration-300 ease-in-out hover:scale-105 focus:outline-none"
                  onClick={(e) => {
                    navigate("/products");

                    const button = e.currentTarget;
                    const wave = document.createElement("span");
                    wave.classList.add("wave-effect");
                    button.appendChild(wave);

                    const rect = button.getBoundingClientRect();
                    const size = Math.max(rect.width, rect.height);
                    wave.style.width = wave.style.height = `${size}px`;
                    wave.style.left = `${e.clientX - rect.left - size / 2}px`;
                    wave.style.top = `${e.clientY - rect.top - size / 2}px`;

                    wave.addEventListener("animationend", () => {
                      wave.remove();
                    });
                  }}
                >
                  BUY NOW
                </button>

                <style jsx>{`
                  .wave-effect {
                    position: absolute;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.5);
                    transform: scale(0);
                    animation: wave 1s ease-out;
                    pointer-events: none;
                  }

                  @keyframes wave {
                    100% {
                      transform: scale(4);
                      opacity: 0;
                    }
                  }
                `}</style>
              </div>
            </div>
          </div>

          <section className="w-full lg:w-[75%] bg-richblack-700 lg:rounded-lg mb-10 mt-10 mx-auto">
            <div className="flex flex-col gap-6 justify-between w-11/12 max-w-maxContent text-white mx-auto">
              <div className="grid grid-cols-3 text-center">
                {Stats?.map((data, index) => {
                  return (
                    <div
                      className="flex flex-col py-4 sm:py-6 md:py-8"
                      key={index}
                    >
                      <h1 className="text-xl sm:text-2xl md:text-4xl font-bold text-richblack-5">
                        {data.count}
                      </h1>
                      <h2 className="font-semibold text-base sm:text-lg md:text-xl text-richblack-200">
                        {data.label}
                      </h2>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          {reviews?.length > 0 && (
            <div className="w-full mx-auto max-w-maxContent flex flex-col items-center justify-between gap-8 bg-red-100 text-richblack-800 sm:w-full sm:px-4">
              <h2 className="text-center text-4xl font-semibold mt-10 sm:text-2xl sm:mt-6">
                Review from Other Learners
              </h2>
              <div className="w-full">
                <ReviewSlider reviews={reviews} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
