import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signupData: null,
  loading: false,
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setSignupData(state, action) {
      state.signupData = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
      if (action.payload) {
        localStorage.setItem("token", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("token");
      }
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    logout(state) {
      state.token = null;
      localStorage.removeItem("token");
    },
  },
});

export const { setSignupData, setToken, setLoading, logout } =
  authSlice.actions;

export const checkTokenExpiration = () => (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1])); // first part ma time hota ha
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp < currentTime) {
        dispatch(logout());
        window.location.href = "/login";
      }
    } catch (error) {
      console.error("Invalid token format:", error);
      dispatch(logout());
      window.location.href = "/login";
    }
  }
};

export default authSlice.reducer;
