import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/common/Navbar";
import Home from "./Pages/Home";
import NewArrivals from "./Pages/NewArrivals";
import Products from "./Pages/Products";
import Faq from "./Pages/Faq";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/common/Footer";
import Profile from "./Pages/Profile";
import Cart from "./Pages/Cart";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Return from "./Pages/Return";
import Shipping from "./Pages/Shipping";
import TermsConditions from "./Pages/TermsConditions";
import SignUp from "./Pages/SignUp";
import LogIn from "./Pages/LogIn";
import Otp from "./Pages/Otp";
import PrivateRoute from "./components/Auth/PrivateRoute";
import OpenRoute from "./components/Auth/OpenRoute";
import NotFound from "./Pages/NotFound";
import Validating from "./Pages/Validating";
import { useDispatch, useSelector } from "react-redux";
import Orders from "./components/Profile/Orders";
import OrdersDetails from "./components/order/OrderDetails";
import Address from "./components/Profile/Address";
import AllProducts from "./components/Profile/AllProducts";
import AddProducts from "./components/Profile/AddProducts";
import RecentOrders from "./components/Profile/RecentOrders";
import EditProducts from "./components/Profile/EditProducts";
import Dashboard from "./components/Profile/Dashboard";
import ViewProduct from "./Pages/ViewProduct";
import Checkout from "./Pages/Checkout";
import { FaWhatsapp } from "react-icons/fa";
import Settings from "./components/Profile/Settings";
import OrderIdDetails from "./components/Profile/OrderIdDetails";
import EditProfile from "./components/Profile/EditProfile";
import { checkTokenExpiration } from "./slices/authSlice";
import { getAllUserData } from "./services/operations/profileAPI";

function App() {
  const { user } = useSelector((state) => state.profile);
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (token) {
          const response = await getAllUserData(token);
          dispatch(setUser(response?.userDetails));
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchUserData();
  }, [token]);

  // Check if the current path is related to the admin profile page
  const isAdminProfilePage =
    user?.role === "admin" && location.pathname.startsWith("/profile");

  useEffect(() => {
    dispatch(checkTokenExpiration());
  }, [dispatch]);

  useEffect(() => {
    // Set the title dynamically based on the current location
    const path = location.pathname;

    if (path === "/") {
      document.title = "DemonGears | Home";
    } else if (path === "/signup") {
      document.title = "DemonGears | Sign Up";
    } else if (path === "/login") {
      document.title = "DemonGears | Log In";
    } else if (path === "/new-arrivals") {
      document.title = "DemonGears | New Arrivals";
    } else if (path === "/products") {
      document.title = "DemonGears | Products";
    } else if (path === "/faq") {
      document.title = "DemonGears | FAQ";
    } else if (path === "/cart") {
      document.title = "DemonGears | Cart";
    } else if (path === "/checkout") {
      document.title = "DemonGears | Checkout";
    } else if (path === "/profile") {
      document.title = "DemonGears | Profile";
    } else if (path === "/about-us") {
      document.title = "DemonGears | About Us";
    } else if (path === "/terms-and-conditions") {
      document.title = "DemonGears | Terms & Conditions";
    } else if (path === "/privacy-policy") {
      document.title = "DemonGears | Privacy Policy";
    } else if (path === "/contact-us") {
      document.title = "DemonGears | Contact Us";
    } else if (path === "/shipping-policy") {
      document.title = "DemonGears | Shipping Policy";
    } else if (path === "/return-policy") {
      document.title = "DemonGears | Return Policy";
    } else if (path === "/validating") {
      document.title = "DemonGears | Validating";
    } else {
      document.title = "DemonGears | Gym Gears";
    }
  }, [location]);

  return (
    <div className="w-screen min-h-screen bg-white flex flex-col font-inter">
      {/* Conditionally render Navbar based on user role and location */}
      {!isAdminProfilePage && <Navbar />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/signup"
          element={
            <OpenRoute>
              <SignUp />
            </OpenRoute>
          }
        />
        <Route
          path="/login"
          element={
            <OpenRoute>
              <LogIn />
            </OpenRoute>
          }
        />
        <Route
          path="/verify-email"
          element={
            <OpenRoute>
              <Otp />
            </OpenRoute>
          }
        />
        <Route path="/new-arrivals" element={<NewArrivals />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:productId" element={<ViewProduct />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/cart" element={<Cart />} />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        >
          <Route path="settings" element={<Settings />} />
          <Route path="settings/edit" element={<EditProfile />} />

          {user?.role === "user" && (
            <>
              <Route path="orders" element={<Orders />} />
              <Route path="orders/:orderId" element={<OrdersDetails />} />
              <Route path="addresses" element={<Address />} />
            </>
          )}

          {user?.role === "admin" && (
            <>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="recent-orders" element={<RecentOrders />} />
              <Route
                path="recent-orders/:orderId"
                element={<OrderIdDetails />}
              />
              <Route path="add-products" element={<AddProducts />} />
              <Route path="allproducts" element={<AllProducts />} />
              <Route
                path="edit-product/:productId"
                element={<EditProducts />}
              />
            </>
          )}
        </Route>

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/shipping-policy" element={<Shipping />} />
        <Route path="/return-policy" element={<Return />} />
        <Route path="/validating" element={<Validating />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* Conditionally render Footer based on user role and location */}
      {!isAdminProfilePage && <Footer />}
      <a
        href="https://wa.me/8882531564" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-icon"
      >
        <FaWhatsapp size={30} />
      </a>
    </div>
  );
}

export default App;
