const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "http://localhost:5000/api/v1";

// auth endpoints
export const authEndpoints = {
  SIGNUP_API: BASE_URL + "/auth/signup",
  SIGNIN_API: BASE_URL + "/auth/login",
  SEND_OTP_API: BASE_URL + "/auth/sendotp",
  CHANGE_PASSWORD_API: BASE_URL + "/auth/changepassword",
};

// profile endpoints
export const profileEndpoints = {
  CREATE_ADDRESS: BASE_URL + "/profile/address/create",
  UPDATE_ADDRESS: BASE_URL + "/profile/address/update",
  DELETE_ADDRESS: BASE_URL + "/profile/address/delete",
  GET_USER_BY_ID: BASE_URL + "/profile/details",
  GET_ALL_USER: BASE_URL + "/profile/all",
  DELETE_USER: BASE_URL + "/profile/delete",
  GET_ALL_USER_DATA_API: BASE_URL + "/profile/getAllUserData",
  UPDATE_DISPLAY_PICTURE_API: BASE_URL + "/profile/updateDisplayPicture",
  GET_ADMIN_DATA_API: BASE_URL + "/profile/adminDashboard",
};

// product endpoints
export const productEndpoints = {
  GET_ALL_PRODUCTS: BASE_URL + "/product/",
  CREATE_PRODUCT: BASE_URL + "/product/create",
  GET_PRODUCT: BASE_URL + "/product",
  UPDATE_PRODUCT: BASE_URL + "/product/update",
  DELETE_PRODUCT: BASE_URL + "/product/delete",
  // PRODUCT_BY_CATEGORY: BASE_URL + "/category",
  GET_ALL_CATEGORY_NAME: BASE_URL + "/product/categories/",
  GET_ALL_REVIEWS: BASE_URL + "/product/reviews",
  CREATE_RATING: BASE_URL + "/product/createRating",
  GET_AVG_RATING: BASE_URL + "/product/getAverageRating",
  GET_ALL_RATING: BASE_URL + "/product/getReviews",
};

// payment endpoints
export const paymentEndpoints = {
  CAPTURE_PAYMENT: BASE_URL + "/payment/capturePayment",
  VERIFY_PAYMENT: BASE_URL + "/payment/verifyPayment",
  SEND_PAYMENT_EMAIL: BASE_URL + "/payment/sendPaymentSuccessEmail",
  GET_INVOICE_HTML: BASE_URL + "/payment/getInvoice",
};

// order endpoints
export const orderEndpoints = {
  CREATE_ORDER_API: BASE_URL + "/order/create",
  GET_ORDER_BY_ID: BASE_URL + "/order/fetch",
  GET_ALL_ORDERS_OF_USER_API: BASE_URL + "/order/user",
  GET_ALL_ORDERS_OF_ADMIN_API: BASE_URL + "/order",
  UPDATE_ORDER_API: BASE_URL + "/order/update",
  CANCEL_ORDER_API: BASE_URL + "/order/cancel/:orderId",
};

// cart endpoints
export const cartEndpoints = {
  GET_CART: BASE_URL + "/cart/get-cart",
  ADD_TO_CART: BASE_URL + "/cart/add-to-cart",
  REMOVE_FROM_CART: BASE_URL + "/cart/remove-from-cart",
};

export const subscribeEndpoints = {
  CREATE_SUBSCRIBER_API: BASE_URL + "/subscribe/subscribeus",
};

export const feedbackEndpoints = {
  CREATE_FEEDBACK_API: BASE_URL + "/feedback/createFeed",
  GET_ALL_FEED_API: BASE_URL + "/feedback/getAllFeed",
};

// SETTINGS PAGE API
export const settingsEndpoints = {
  UPDATE_DISPLAY_PICTURE_API: BASE_URL + "/profile/updateDisplayPicture",
  UPDATE_PROFILE_API: BASE_URL + "/profile/updateProfile",
  CHANGE_PASSWORD_API: BASE_URL + "/auth/changepassword",
  DELETE_PROFILE_API: BASE_URL + "/profile/deleteProfile",
};
