import React, { useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../slices/authSlice";
import { setUser } from "../slices/profileSlice";

const Validating = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(token));
    dispatch(setToken(token));
    const fetchUserData = async () => {
      try {
        if (token) {
          const response = await getAllUserData(token);
          dispatch(setUser(response?.userDetails));
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchUserData();
    navigate("/profile/settings");
  }, [token]);

  return (
    <div className="flex flex-col w-full h-screen items-center justify-center">
      <Spinner color="red" className="h-10 w-10" />
    </div>
  );
};

export default Validating;
