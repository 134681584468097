import { useEffect, useState } from "react";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, matchPath, useLocation } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import logo from "../../assets/Home/logo.png";
import { NavbarLinks } from "../../data/navbar-links";

function Navbar() {
  const location = useLocation();
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const { totalItems } = useSelector((state) => state.cart);
  const isAdminProfilePage =
    user?.role === "admin" && location.pathname.startsWith("/profile");
  const [subLinks, setSubLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setShowNavbar(false); // Scrolling down
      } else {
        setShowNavbar(true); // Scrolling up
      }
      setLastScrollY(currentScrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  // Scroll to top when navigating to a new page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const matchRoute = (route) => {
    return matchPath({ path: route }, location.pathname);
  };

  return (
    <div
      className={`fixed top-0 w-full z-50 transition-transform duration-300 ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      } flex h-16 items-center justify-center border-b-[1px] border-b-richblack-25 bg-white`}
    >
      <div className="flex w-10/12 max-w-maxContent items-center justify-between md:justify-between">
        <button
          className="md:hidden text-black"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? (
            <AiOutlineClose fontSize={24} fill="#AFB2BF" />
          ) : (
            <AiOutlineMenu fontSize={24} fill="#AFB2BF" />
          )}
        </button>

        <Link to="/" className="mx-auto md:mx-0 md:flex-shrink-0">
          <img src={logo} alt="Logo" width={160} height={32} loading="lazy" />
        </Link>

        <div className="flex gap-4 md:hidden">
          {(!user || user?.role === "user") && (
            <Link to="/cart" className="relative inline-block">
              <AiOutlineShoppingCart className="text-2xl text-black" />
              {totalItems > 0 && (
                <span className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-center text-xs font-bold text-richblack-800 sm:h-6 sm:w-6 sm:text-sm">
                  {totalItems}
                </span>
              )}
            </Link>
          )}

          <Link
            to={
              user
                ? user.role === "admin"
                  ? "/profile/dashboard"
                  : user.role === "student"
                  ? "/profile/settings"
                  : "/login"
                : "/login"
            }
          >
            <RxAvatar className="text-2xl text-black" />
          </Link>
        </div>

        <nav className="hidden md:block">
          <ul className="flex gap-x-10 text-black">
            {NavbarLinks.map((link, index) => (
              <li key={index}>
                {link.title === "Category" ? (
                  <div
                    className={`group relative flex cursor-pointer items-center gap-1 ${
                      matchRoute("/Category/:CategoryName")
                        ? "font-bold text-transparent bg-clip-text bg-gradient-to-r from-richred-500 to-black"
                        : "text-black"
                    } transition-all duration-300`}
                  >
                    <p>{link.title}</p>
                    <BsChevronDown />
                    <div
                      className="absolute left-[50%] top-[50%] z-[1000] flex w-[200px] translate-x-[-50%] 
                      translate-y-[3em] flex-col rounded-lg bg-black p-4 text-black-900 opacity-0 transition-all duration-150 
                      group-hover:visible group-hover:translate-y-[1.65em] group-hover:opacity-100 lg:w-[300px]"
                    >
                      <div className="absolute left-[50%] top-0 -z-10 h-6 w-6 translate-x-[80%] translate-y-[-40%] rotate-45 select-none rounded bg-black"></div>
                      {loading ? (
                        <p className="text-center">Loading...</p>
                      ) : subLinks && subLinks.length ? (
                        subLinks
                          ?.filter((subLink) => subLink?.courses?.length > 0)
                          ?.map((subLink, i) => (
                            <Link
                              to={`/catalog/${subLink.name
                                .split(" ")
                                .join("-")
                                .toLowerCase()}`}
                              className="rounded-lg bg-transparent py-4 pl-4 hover:bg-white"
                              key={i}
                            >
                              <p>{subLink.name}</p>
                            </Link>
                          ))
                      ) : (
                        <p className="text-center">No Category Found</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <Link to={link?.path}>
                    <p
                      className={`${
                        matchRoute(link?.path)
                          ? "font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045]"
                          : "text-black font-medium"
                      } transition-all duration-300 hover:underline`}
                    >
                      {link.title}
                    </p>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className="hidden md:flex items-center gap-x-5">
          {(!user || user?.role === "user") && (
            <Link to="/cart" className="relative inline-block">
              <AiOutlineShoppingCart className="text-2xl text-black" />
              {totalItems > 0 && (
                <span className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-center text-xs font-bold text-richblack-800 sm:h-6 sm:w-6 sm:text-sm">
                  {totalItems}
                </span>
              )}
            </Link>
          )}

          <Link
            to={
              token
                ? user
                  ? user.role === "admin"
                    ? "/profile/dashboard"
                    : user.role === "user"
                    ? "/profile/settings"
                    : "/login"
                  : "/login"
                : "/login"
            }
          >
            <RxAvatar className="text-2xl text-black" />
          </Link>
        </div>
      </div>

      {isMenuOpen && (
        <div className="absolute top-16 left-0 right-0 bg-white p-6 shadow-lg md:hidden">
          <ul className="flex flex-col gap-4">
            {NavbarLinks.map((link, index) => (
              <li key={index}>
                <Link
                  to={link?.path}
                  className="block text-black text-lg font-medium"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;
