import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllUserData } from "../../services/operations/profileAPI";
import { getAllOrdersForUser } from "../../services/operations/order";
import { StepperWithContent } from "./StepperWithContent";
import { IoIosArrowDown } from "react-icons/io";
import AddReviewModal from "../Product/AddReviewModal";
import { Rating } from "@material-tailwind/react";
import DefaultButton from "../common/DefaultButton";
import IconBtn from "../common/IconBtn";
import { generateInvoice } from "../../utils/billGenerator";
import dayjs from "dayjs";

const OrdersDetails = () => {
  const { orderId } = useParams(); // Get orderId from URL parameters
  const { token } = useSelector((state) => state.auth);
  const [user, setUser] = useState(null);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // To programmatically navigate

  useEffect(() => {
    const fetchUserDataAndOrderDetails = async () => {
      setLoading(true);
      try {
        // Fetch user data
        const userResponse = await getAllUserData(token);
        setUser(userResponse.userDetails);

        // Fetch all orders for the user
        const ordersResponse = await getAllOrdersForUser(
          userResponse.userDetails._id,
          token
        );

        // Find the specific order by orderId
        const foundOrder = ordersResponse.orders.find(
          (order) => order._id === orderId
        );
        setOrder(foundOrder);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching user data or order details", err);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchUserDataAndOrderDetails();
    }
  }, [token, orderId]); // Re-run effect if token or orderId changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user || !order) {
    return <div>No user or order data found.</div>;
  }

  // Function to render the address
  const renderAddress = (address) => (
    <div className="border p-4 rounded-lg mt-16">
      <h3 className="text-lg font-semibold">Shipping Address</h3>
      <p>
        {address?.firstName} {address?.lastName}-
      </p>
      <p>{address?.address1}</p>
      {address?.address2 && <p>{address?.address2}</p>}
      <p>
        {address?.city}, {address?.zipCode}
      </p>
      <p>{address?.country}</p>
      <p>Contact: {address?.contactNumber}</p>
    </div>
  );

  // Handle navigation to product page
  const navigateToProductPage = (productId) => {
    navigate(`/product/${productId}`);
  };
  const expectedDeliveryDate = dayjs(order.createdAt)
    .add(5, "day")
    .format("DD MMMM YYYY");

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-4">Order Details</h2>

      <div className="flex flex-wrap">
        {/* Left: Product Image and Details */}
        <div className="w-full md:w-1/2 p-4">
          <img
            src={order.orderItems[0].product.images[0].images_url[0]}
            alt={order.orderItems[0].product.name}
            className="w-full h-auto mb-4"
          />
          <h3 className="text-2xl font-bold">
            Demongears - {order.orderItems[0].product.name}
          </h3>
          <div className="flex  items-center  mt-5">
            <p className="font-semibold mr-4 text-2xl text-green-600">
              ₹{order.orderItems[0].product?.discountedPrice}
            </p>
            <p className="line-through mr-3 text-2xl text-richblack-400">
              ₹{order.orderItems[0].product?.originalPrice}
            </p>
            <p className="text-sm text-red-500 ">
              {100 -
                Math.floor(
                  (order.orderItems[0].product?.discountedPrice /
                    order.orderItems[0].product?.originalPrice) *
                    100
                )}
              % off
            </p>
          </div>

          <p>Color: {order.orderItems[0].product.images[0].color}</p>
          {/* <p>Price: ₹{order.orderItems[0].product.discountedPrice}</p>
          <p>Delivery: ₹49</p>
          <p>Total Price: ₹{order.totalAmount}</p> */}

          {/* Down Arrow to navigate to product page */}

          {/* Green line for expected delivery */}
          {order.orderStatus !== "Delivered" && (
            <p className="text-green-600 font-semibold mt-4">
              Your order will be delivered on {expectedDeliveryDate}
            </p>
          )}

          <div
            onClick={() =>
              navigateToProductPage(order.orderItems[0].product._id)
            }
            className="flex justify-center items-center mt-4 cursor-pointer"
          >
            <IoIosArrowDown size={30} />
          </div>
        </div>

        {/* Right: Shipping Address and Order Status Timeline */}
        <div className="w-full md:w-1/2 p-4">
          {/* Address Section */}
          {order.shippingAddress &&
            renderAddress(
              user.addresses.find(
                (address) => address.address1 === order.shippingAddress.address1
              )
            )}

          <div className="w-full p-4">
            {order?.orderStatus === "Delivered" ? (
              <div className="flex flex-col gap-5">
                <p className="text-lg ">Download your invoice</p>
                <IconBtn
                  onclick={() => generateInvoice(orderId, token)}
                  customClasses={"flex item-center justify-center"}
                >
                  Download
                </IconBtn>
              </div>
            ) : (
              <p className="text-center text-richblack-100 text-lg ">
                Once the order will be delivered you will be able to downlad the
                invoice{" "}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="border rounded-lg p-4 shadow-sm col-span-1 md:col-span-2">
            <h2 className="text-lg font-semibold mb-4">Order Items</h2>
            <table className="w-full border-collapse border border-gray-200 text-sm">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 p-2 text-left">Item</th>
                  <th className="border border-gray-300 p-2 text-left">
                    Quantity
                  </th>
                  <th className="border border-gray-300 p-2 text-left">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Render all order items */}
                {order.orderItems.map((item) => (
                  <tr key={item._id}>
                    <td className="border border-gray-300 p-2">
                      {item?.product?.name}
                    </td>
                    <td className="border border-gray-300 p-2">
                      {item?.quantity}
                    </td>
                    <td className="border border-gray-300 p-2">
                      ₹{item?.price} × {item?.quantity} = ₹
                      {(item?.price * item?.quantity).toFixed(2)}
                    </td>
                  </tr>
                ))}
                {/* Delivery charge row */}
                <tr>
                  <td className="border border-gray-300 p-2 font-semibold">
                    Delivery Charge
                  </td>
                  <td className="border border-gray-300 p-2 text-center">-</td>
                  <td className="border border-gray-300 p-2">₹49</td>
                </tr>
                {/* Total amount row */}
                <tr className="bg-gray-100">
                  <td className="border border-gray-300 p-2 font-bold">
                    Total Amount
                  </td>
                  <td className="border border-gray-300 p-2 text-center">-</td>
                  <td className="border border-gray-300 p-2 font-bold">
                    ₹{order.totalAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* rating and review  */}
          <div className="my-10 w-full sm:w-1/2 ml-5 px-4">
            <div className="w-full">
              <h2 className="text-2xl sm:text-3xl font-bold text-center sm:text-left">
                Rating and Reviews
              </h2>
              <div className="mt-5 flex flex-col sm:flex-row gap-3 items-center">
                <p className="text-lg text-center sm:text-left ring-richblack-100">
                  Want to Give Feedback?
                </p>
                <AddReviewModal productId={order.orderItems[0].product._id} />
              </div>
              <div className="mt-5">
                {order.orderItems[0].product?.ratingAndReviews?.length ? (
                  <div className="flex flex-col gap-5">
                    {order.orderItems[0].product?.ratingAndReviews?.map(
                      (item) => (
                        <div
                          className="p-4 bg-red-50 rounded-xl"
                          key={item._id}
                        >
                          <div className="font-bold capitalize text-lg flex justify-between items-start">
                            <h3>
                              {item.user.firstName} {item.user.lastName}
                            </h3>
                            <span className="flex flex-col items-end">
                              <Rating
                                value={Math.round(item.rating)}
                                readonly
                                unratedColor="amber"
                                ratedColor="amber"
                              />
                            </span>
                          </div>
                          <p className="text-md mt-2">{item.review}</p>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="flex items-center justify-center mt-7 text-richblack-200">
                    <p>No Reviews Yet! Be the first to Review</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Order Status Timeline */}
        <StepperWithContent order={order} />
      </div>
    </div>
  );
};

export default OrdersDetails;
